<template>
  <div>
    <!-- 头部 -->
    <mHeader></mHeader>
    <!-- 内容 -->
    <div class="neir">
      <div class="neir-box">
        <div @click="backBtn" class="back">
          <SvgIcon
            :style="{
              fontSize: '12px',
              marginRight: '6px',
            }"
            iconClass="back"
          ></SvgIcon
          >Back
        </div>
        <!-- <div class="title">{{ htmlData.title }}</div> -->
        <div v-html="htmlData.content" class="neir-main"></div>
      </div>
    </div>
  </div>
</template>
<script>
import mHeader from "@/components/m_header.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { get_service } from "@/api/common";
export default {
  data() {
    return {
      htmlData: "",
    };
  },
  methods: {
    backBtn() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_service") {
        this.$router.replace("/m_service");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/service") {
        this.$router.replace("/service");
      }
    }
  },
  async created() {
    let res = await get_service();
    // console.log(res);
    this.htmlData = res.data.data;
    // console.log(this.htmlData);
  },
  components: {
    mHeader,
    SvgIcon,
  },
};
</script>


<style lang="less" scoped>
.neir {
  margin: 0 auto;
  padding: 0 16px;
  color: #12334c;

  .neir-box {
    padding: 24px 0 66px;
    .back {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 700;
    }
    .title {
      font-weight: bold;
      font-size: 22px;
      color: #12334c;
      margin-bottom: 24px;
    }

    .neir-main {
      .item {
        margin-bottom: 28px;
        .item-da-tit {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 12px;
        }
        .item-xiao-tit {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .item-zhon-tit {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 16px;
        }
        .ppp {
          margin-bottom: 16px;
        }
        .item-main {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 40px;
          p {
            margin-bottom: 16px;
          }
          .dl {
            padding-left: 16px;
          }
        }
      }
    }
  }
}
</style>